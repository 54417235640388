/* eslint-disable */
import { Grid } from '@rentacenter/racstrap';
import React, { useContext } from 'react';

import { ReactComponent as TickIcon } from '../images/success-icon.svg';
import { ReactComponent as WhiteIcon } from '../images/white-image.svg';
import { dofContextValue } from '../context/DofContext';

import { DofCssClasses } from '../styles/dofStyles';


export type WizardProps = {
  BasicInfoStatus: boolean;
  EmployerStatus: boolean;
  ResidenceStatus: boolean;
  ReferenceStatus: boolean;
};

export default function Wizard(props: WizardProps) {
  const { customerDetails } = useContext(dofContextValue);
  const wizardClassName: any = DofCssClasses();

  const { BasicInfoStatus, EmployerStatus, ResidenceStatus, ReferenceStatus } =
    props;

  return (
    <Grid container lg={12} className={wizardClassName.smartWizard}>
      <Grid
        item
        lg={12}
        className={`${wizardClassName.flexBasis0} ${wizardClassName.smartWizardList}`}
      >
        {BasicInfoStatus ? (
          < >
            <TickIcon />
            <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }} >Basic Information</span>
          </ >


        ) : (
          <>
            <WhiteIcon className={wizardClassName.borderDone} />
            <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }}>Basic Information</span>
          </>

        )}

      </Grid>

      <Grid
        item
        lg={12}
        className={`${wizardClassName.flexBasis0} ${wizardClassName.smartWizardList}`}
      >
        {EmployerStatus ? (
          <>
            <TickIcon />
            <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }}>Employer</span>
          </>
        ) : (
          <>
            <WhiteIcon className={wizardClassName.borderDone} />
            <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }}>Employer</span>
          </>

        )}
      </Grid>

      {customerDetails?.approvalResponse != '1' ?
        <>
          <Grid
            item
            lg={12}
            className={`${wizardClassName.flexBasis0} ${wizardClassName.smartWizardList}`}
          >
            {ResidenceStatus ? (
              <>
                <TickIcon />
                <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }}>Residence</span>
              </>

            ) : (
              <>
                <WhiteIcon className={wizardClassName.borderDone} />
                <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }}>Residence</span>

              </>

            )}

          </Grid>

          <Grid
            item
            lg={12}
            className={`${wizardClassName.flexBasis0} ${wizardClassName.smartWizardList}`}
          >
            {ReferenceStatus ? (
              <>
                <TickIcon />
                <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }}>Reference</span>
              </>
            ) : (
              <>
                <WhiteIcon className={wizardClassName.borderDone} />
                <span className={wizardClassName.fontSizeXSM} style={{ fontFamily: 'OpenSans-semibold', display: 'block' }}>Reference</span>

              </>
            )}

          </Grid>
        </> : null}
    </Grid>
  );
}
