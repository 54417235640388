/* eslint-disable */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  Grid,
  RACTextbox,
  RACDatePicker,
  RACRadio,
  RACCheckBox,
  RACButton,
  Typography,
} from '@rentacenter/racstrap';
import { DofCssClasses } from '../styles/dofStyles';
import { dofContextValue } from '../context/DofContext';
import React, { useContext, useState } from 'react';
import DofHeaders from './DofHeaders';
import { Validator } from '../utils/utils';
import { useHistory } from 'react-router-dom';
import HeadersStyles from './HeadersStyles';
import { VerifyInputValues } from '../interfaces/interfaces';
import moment from 'moment';

export default function Residence() {
  const {
    residenceDetails,
    setResidenceDetails,
    setIsPrevious,
    isVerified,
    setIsVerified,
    formatedLLPhone,
    setFormatedLLPhone
  } = useContext(dofContextValue);

  const history = useHistory();
  const dofClassName: any = DofCssClasses();


  // Function for handle input change 

  const handleInput = (e: any) => {
    if (e.target.name === 'rentorown') {
      setResidenceDetails({
        ...residenceDetails,
        residenceInfo: e.target.value,
      });
    } else if (e.target.name === 'mortgage') {
      if (residenceDetails.isMortgage) {
        setResidenceDetails({
          ...residenceDetails,
          isMortgage: false,
        });
      } else {
        setResidenceDetails({
          ...residenceDetails,
          isMortgage: true,
        });
      }
    } else if (e.target.name === 'landlord_first_name') {
      const response = Validator(e.target.value, 'name');
      setResidenceDetails({
        ...residenceDetails,
        landlordFirstName: response[0],
      });
      setIsVerified((event: VerifyInputValues) => ({
        ...event,
        landLordFirstName: response[1],
      }));
    } else if (e.target.name === 'landlord_last_name') {
      const response = Validator(e.target.value, 'name');
      setResidenceDetails({
        ...residenceDetails,
        landlordLastName: response[0],
      });
      setIsVerified((event: VerifyInputValues) => ({
        ...event,
        landLordLastName: response[1],
      }));
    } else if (e.target.name === 'landlord_phone') {
      const response = Validator(e.target.value, 'phoneNumber');
      setResidenceDetails({
        ...residenceDetails,
        landlordPhoneNumber: response[0],
      });
      setIsVerified((event: VerifyInputValues) => ({
        ...event,
        landLordPhoneNumber: response[1],
      }));
      setFormatedLLPhone(response[2]);
    } else if (e.target.name === 'rent_payment_amount') {
      setResidenceDetails({
        ...residenceDetails,
        rentPayment: e.target.value,
      });
    } else if (e.target.name === 'mortage_company_name') {
      const response = Validator(e.target.value, 'address');
      setResidenceDetails({
        ...residenceDetails,
        mortgageCompanyName: response[0],
      });
      setIsVerified((event: VerifyInputValues) => ({
        ...event,
        mortgageCompany: response[1],
      }));
    } else if (e.target.name === 'own_payment_amount') {
      setResidenceDetails({
        ...residenceDetails,
        ownMortgagePayment: e.target.value,
      });
    }
  };
  // Function for  ontinue button click

  const handleContinueBtn = () => {
    history.push('/reference');
  };
  // Function for previous button click

  const handlePreviousBtn = () => {
    setIsPrevious(true);
    history.push('/employer');
  };

  return (
    <Grid>
      <DofHeaders />
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={`${dofClassName.floatCenter}`}
      >
        <Grid container lg={6} md={8} sm={10} xs={10}>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.displayFlex} ${dofClassName.floatCenter}`}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <HeadersStyles
                BasicInfoStatus={true}
                EmployerStatus={true}
                ResidenceStatus={true}
                ReferenceStatus={false}
              />
            </Grid>

            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.floatLeft} ${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <Typography className={`${dofClassName.existCustTitle}`}>
                {' '}
                Please give us some information about your residence{' '}
              </Typography>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={` ${dofClassName.marginInputFields}`}
              style={{ textAlign: 'left' }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={` ${dofClassName.marginInputFields} ${dofClassName.marginLeftRight}`}
              >
                <Typography> Do you rent or own? </Typography>
              </Grid>

              <Grid>
                <RACRadio
                  name="rentorown"
                  checked={
                    residenceDetails.residenceInfo &&
                      residenceDetails.residenceInfo === 'Rent'
                      ? true
                      : false
                  }
                  value={'Rent'}
                  onClick={(e) => {
                    handleInput(e);
                  }}
                  label="Rent"
                />
                <RACRadio
                  name="rentorown"
                  checked={
                    residenceDetails.residenceInfo &&
                      residenceDetails.residenceInfo === 'Own'
                      ? true
                      : false
                  }
                  value={'Own'}
                  onClick={(e) => {
                    handleInput(e);
                  }}
                  label="Own"
                  className={dofClassName.marginLeft}
                />
              </Grid>
            </Grid>

            {residenceDetails.residenceInfo === 'Rent' ? (
              <Grid container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <RACTextbox
                    inputlabel="Landlord’s First Name"
                    name="landlord_first_name"
                    required={true}
                    value={residenceDetails.landlordFirstName}
                    maxlength={30}
                    type="text"
                    OnChange={(e: any) => handleInput(e)}
                    inputLabelClassname={dofClassName.marginBottomforInput}
                    className={`${dofClassName.inputBoxHeight}`}
                  />
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <RACTextbox
                    inputlabel="Landlord’s Last Name"
                    name="landlord_last_name"
                    required={true}
                    value={residenceDetails.landlordLastName}
                    maxlength={30}
                    type="text"
                    OnChange={(e: any) => handleInput(e)}
                    inputLabelClassname={dofClassName.marginBottomforInput}
                    className={`${dofClassName.inputBoxHeight}`}
                  />
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <RACTextbox
                    inputlabel="Landlord’s Phone #"
                    name="landlord_phone"
                    required={true}
                    value={formatedLLPhone}
                    maxlength={14}
                    type="text"
                    OnChange={(e: any) => handleInput(e)}
                    inputLabelClassname={dofClassName.marginBottomforInput}
                    className={`${dofClassName.inputBoxHeight}`}
                  />
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <div className={dofClassName.datePickerClass}>
                    <RACDatePicker
                      label="Move in Date"
                      name="rent_move_in_date"
                      required={false}
                      disabled={false}
                      value={residenceDetails.rentMoveInDate}
                      inputProps={{
                        max: moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD'),
                      }}
                      onChange={(e: any) => {
                        const response = Validator(e, 'date');
                        setResidenceDetails({
                          ...residenceDetails,
                          rentMoveInDate: response[0],
                        });
                      }}
                      classes={{
                        input: dofClassName.inputBoxHeight,
                        label: dofClassName.textAlignLeft
                      }}
                    />
                  </div>
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <RACTextbox
                    inputlabel="Payment Amount"
                    isCurrency={true}
                    name="rent_payment_amount"
                    required={false}
                    digitFormat={'currency'}
                    value={
                      residenceDetails.rentPayment
                        ? residenceDetails.rentPayment
                        : '0.00'
                    }
                    maxlength={9}
                    type="number"
                    OnChange={(e: any) => handleInput(e)}
                    inputLabelClassname={dofClassName.marginBottomforInput1}
                    className={`${dofClassName.inputBoxHeight} ${dofClassName.textAlignRightForAmount}`}
                    dollarTextClassName={dofClassName.dollarClass}
                  />
                </Grid>
              </Grid>
            ) : residenceDetails.residenceInfo === 'Own' ? (
              <Grid container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginInputFields}`}
                >
                  <Grid style={{ textAlign: 'left' }}>
                    <RACCheckBox
                      label="Mortgage"
                      name="mortgage"
                      checked={residenceDetails.isMortgage}
                      value={residenceDetails.isMortgage}
                      onClick={(e) => {
                        handleInput(e);
                      }}
                    />
                  </Grid>
                </Grid>

                {/* Move in date */}
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                >
                  <div className={dofClassName.datePickerClass}>
                    <RACDatePicker
                      label="Move in Date"
                      name="own_move_in_date"
                      required={false}
                      disabled={false}
                      value={residenceDetails.ownMoveInDate}
                      inputProps={{
                        max: moment()
                          .subtract(18, 'years')
                          .format('YYYY-MM-DD'),
                      }}
                      onChange={(e: any) => {
                        const response = Validator(e, 'date');
                        setResidenceDetails({
                          ...residenceDetails,
                          ownMoveInDate: response[0],
                        });
                      }}
                      classes={{
                        input: dofClassName.inputBoxHeight,
                        label: dofClassName.textAlignLeft
                      }}
                    />
                  </div>
                </Grid>

                {residenceDetails.isMortgage ? (
                  <Grid container>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                    >
                      <RACTextbox
                        inputlabel="Mortgage Company Name"
                        name="mortage_company_name"
                        required={true}
                        value={residenceDetails.mortgageCompanyName}
                        maxlength={30}
                        type="text"
                        OnChange={(e: any) => handleInput(e)}
                        inputLabelClassname={dofClassName.marginBottomforInput}
                        className={`${dofClassName.inputBoxHeight}`}
                      />
                    </Grid>

                    {/* Payment Amount */}
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
                    >
                      <RACTextbox
                        inputlabel="Payment Amount"
                        name="own_payment_amount"
                        isCurrency={true}
                        type={'number'}
                        digitFormat={'currency'}
                        required={false}
                        value={
                          residenceDetails.ownMortgagePayment
                            ? residenceDetails.ownMortgagePayment
                            : '0.00'
                        }
                        maxlength={9}
                        disabled={false}
                        OnChange={(e: any) => handleInput(e)}
                        inputLabelClassname={dofClassName.marginBottomforInput1}
                        className={`${dofClassName.inputBoxHeight} ${dofClassName.textAlignRightForAmount}`}
                        dollarTextClassName={dofClassName.dollarClass}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            ) : (
              ''
            )}

            <Grid
              container
              spacing={2}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.topMargin} ${dofClassName.spaceBetween}`}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className={dofClassName.textAlignLeft}
              >
                <RACButton
                  // className={`${dofClassName.btn} ${dofClassName.btnPrevious}`}
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    handlePreviousBtn();
                  }}
                >
                  Previous
                </RACButton>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className={dofClassName.textAlignRight}
              >
                <RACButton
                  onClick={() => {
                    handleContinueBtn();
                  }}
                  color="primary"
                  variant="contained"
                  disabled={
                    residenceDetails.residenceInfo === 'Rent' &&
                      !(
                        (isVerified.landLordFirstName &&
                          isVerified.landLordLastName &&
                          isVerified.landLordPhoneNumber) || (residenceDetails.landLordFirstName && residenceDetails.landLordLastName && residenceDetails.landLordPhoneNumber)
                      )
                      ? true
                      : residenceDetails.residenceInfo === 'Own' &&
                        residenceDetails.isMortgage &&
                        !(isVerified.mortgageCompany || residenceDetails.mortgageCompanyName)
                        ? true
                        : residenceDetails.residenceInfo === '' ? true : false
                  }
                >
                  Continue
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
          {/* </Card> */}
        </Grid>
      </Grid>
    </Grid >
  );
}
