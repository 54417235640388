/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DofHeader from './DofHeaders';
import { Card, CardContent, Grid, Typography } from '@rentacenter/racstrap';
import { DofCssClasses } from '../styles/dofStyles';

export default function IdentityVerify() {
  const classes: any = DofCssClasses();
  const [approvalStatus, setApprovalStatus] = useState<string>('');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const statuss = params.get('status') || '';
    console.log(statuss, 'value of status inside the useEffect');
    validatePage(statuss);
  }, []);

  function validatePage(status: string) {
    console.log(status, 'status inside');
    if (status == 'Completed') {
      setApprovalStatus('Completed');
    } else if (status == 'Expired') {
      setApprovalStatus('Expired');
    } else if (status == 'Rejected') {
      setApprovalStatus('Rejected');
    } else if (status == 'Existing') {
      setApprovalStatus('Existing');
    } else if (status == 'Exception') {
      setApprovalStatus('Exception');
    } else {
      setApprovalStatus('Invalid');
    }
  }
  console.log(approvalStatus, 'staus');
  return (
    <Grid>
      <DofHeader />

      <Grid
        container
        md={12}
        sm={12}
        xs={12}
        className={`${classes.forjustify}`}
      >
        <Grid item md={6} sm={12} xs={12} className={`${classes.forspace}`}>
          <Card className="forbg">
            <CardContent>
              {approvalStatus == 'Completed' ? (
                <Typography className={`${classes.forfont}`}>
                  Link has been already used
                </Typography>
              ) : approvalStatus == 'Exception' ? (
                <Typography className={`${classes.forfont}`}>
                  Something went wrong please contact administrator
                </Typography>
              ) : approvalStatus == 'Existing' ? (
                <Grid>
                  <Typography
                    className={`${classes.forfont}`}
                    variant="h5"
                    style={{ color: '#2179FE' }}
                  >
                    We found your profile information!
                  </Typography>
                  <Typography
                    className={`${classes.forfont} ${classes.foralign}`}
                  >
                    Our Coworker will work with you to
                  </Typography>
                  <Typography
                    className={`${classes.forfont} ${classes.foralign}`}
                  >
                    Complete the order process.
                  </Typography>
                </Grid>
              ) : approvalStatus == 'Rejected' ? (
                <Grid>
                  <Typography
                    variant="h5"
                    style={{ color: '#2179FE', fontSize: '15px', fontFamily: 'OpenSans-bold' }}
                  >
                    Thank you for your application request.
                  </Typography>
                  <Typography
                    className={` ${classes.foralign}`}
                    style={{ fontSize: '15px', fontFamily: 'OpenSans-bold' }}

                  >
                    {' '}
                    Unfortunately, we were unable to approve your request at
                    this time.
                  </Typography>
                  <Typography
                    className={` ${classes.foralign}`}
                    style={{ fontSize: '15px', fontFamily: 'OpenSans-bold' }}

                  >
                    {' '}
                    In 3-10 business days you will receive a detailed email with
                    reason(s) why we were unable to approve your application. If
                    the email is unable to be delivered, you will receive a
                    letter by mail.
                  </Typography>
                  <Typography
                    className={` ${classes.foralign}`}
                    style={{ fontSize: '15px', fontFamily: 'OpenSans-bold' }}

                  >
                    {' '}
                    Please feel free to reapply in 60 days.
                  </Typography>
                </Grid>
              ) : approvalStatus == 'Invalid' ? (
                <Typography className={`${classes.forfont}`}>
                  {' '}
                  Please enter the valid URL
                </Typography>
              ) : approvalStatus == 'Expired' ? (
                <Typography className={`${classes.forfont}`}>
                  {' '}
                  Link has been Expired
                </Typography>
              ) : (
                <></>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
