/* eslint-disable */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-gratuitous-expressions */
/* eslint-disable sonarjs/cognitive-complexity */
// eslint-disable-next-line sonarjs/no-duplicated-branches
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-duplicated-branches */
import React, { useState, useEffect, useContext } from 'react';
import { dofContextValue } from '../context/DofContext';

import DofHeaders from '../components/DofHeaders';
import {
  RACTextbox,
  Grid,
  Typography,
  RACButton,
  RACModalCard,
  RACCheckBox
} from '@rentacenter/racstrap';
import { Validator as valid } from '../utils/utils';
import { DofCssClasses } from '../styles/dofStyles';
import { useHistory } from 'react-router-dom';
import { ValidateOTP, sendOTP, updateAccessLink } from '../api/user';
import { LoaderPopup } from './LoderPopup';

export default function UserInfo() {
  const classes: any = DofCssClasses();

  const history = useHistory();
  type arrayvalidator = Array<string | boolean>;
  type phoneValidator = Array<string | boolean>;
  const [mobNoValidationText, setMobileNoValidationText] = useState('');
  const [isMobileNoVerified, setIsMobileNoVerified] = useState(false);
  const [isFirstNameVerified, setIsFirstNameVerified] = useState(false);
  const [isLastNameVerified, setIsLastNameVerified] = useState(false);
  const [isLastFourSSNVerified, setIsLastFourSSNVerified] = useState(false);
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [messageId, setMessageId] = useState('');
  const [OTPValue, setOTPValue] = useState('');
  const [isLookingUpOpen, setIsLookingUpOpen] = useState(false);
  const [OTPOpen, setIsOTPOpen] = useState(false);
  const [otpValidationText, setOTPValidationText] = useState('');
  const [ActualOTP, setActualOtp] = useState<any>('');

  let { customerDetails, setCustomerDetails, isEmailVerified, setIsEmailVerified } = useContext(dofContextValue);
  useEffect(() => {
    bindDefaultValue();
    console.log(customerDetails, 'inside the basic');
  }, []);

  const continueDisable = !(customerDetails.firstName && customerDetails.lastName && customerDetails.unFormattedPhoneNumber.length == 10 && customerDetails.email && isEmailVerified && (customerDetails.lastFourSsn ? customerDetails.lastFourSsn.length == 4 ? true : false : true));



  function bindDefaultValue() {
    // Get AuthKey and StoreCode from params using URLSearchParams(window.location.search)
    const params = new URLSearchParams(window.location.search);
    const authKey = params.get('AuthKey');
    const storeCode = params.get('StoreCode');

    // Setstating the Authkey value using setAuthkey.
    if (authKey) setCustomerDetails({ ...customerDetails, authKey: authKey });
    if (storeCode)
      setCustomerDetails({ ...customerDetails, storeCode: storeCode });
  }

  function formatPhoneNumber(value: any) {
    //creating a variable formatPhoneNumber and calling the function valid and passing the parameters value and 'phoneNumber'
    const formatPhoneNumber: phoneValidator = valid(
      value,
      'phoneNumber'
    ) as phoneValidator;
    console.log(formatPhoneNumber, 'number');

    setCustomerDetails({
      ...customerDetails,
      mobileNumber: formatPhoneNumber[2],
      unFormattedPhoneNumber: formatPhoneNumber[0],
    });

    // Set SetPhoneNumber with formatedMobNumber[1]

    // checking the formatPhoneNumber[2] if it have any values then it will invoke the if condition.
    if (formatPhoneNumber[1]) {
      //setIsMobileNoVerified(true) and enableContinueButton('MobileNumber')
      setIsMobileNoVerified(true);
      enableContinueButton('MobileNumber');
    } else {
      //setIsMobileNoVerified(false) and setDisableContinueButton(true)
      setIsMobileNoVerified(false);
      setDisableContinueButton(true);
    }
  }


  // Function for enabling the continut button
  function enableContinueButton(field: string) {
    switch (field) {
      case 'MobileNumber':
        if (
          isEmailVerified &&
          isLastFourSSNVerified &&
          isFirstNameVerified
        ) {
          setDisableContinueButton(false);
        } else {
          setDisableContinueButton(true);
        }
        break;
      case 'Email':
        if (
          isLastFourSSNVerified &&
          isMobileNoVerified &&
          isFirstNameVerified
        ) {
          setDisableContinueButton(false);
        } else {
          setDisableContinueButton(true);
        }
        break;
      case 'FirstName':
        if (
          isEmailVerified &&
          isMobileNoVerified &&
          isLastNameVerified
        ) {
          setDisableContinueButton(false);
        } else {
          setDisableContinueButton(true);
        }
        break;
      case 'LastName':
        if (
          isEmailVerified &&
          isMobileNoVerified &&
          isFirstNameVerified
        ) {
          setDisableContinueButton(false);
        } else {
          setDisableContinueButton(true);
        }
        break;
    }
  }
  // Function for EmailValueVerify

  function EmailValueVerify(value: string) {
    // Check if value.trim().length is less than or equals to 50
    const formattedEmail: arrayvalidator = valid(
      value,
      'email'
    ) as arrayvalidator;
    console.log(formattedEmail, 'emil');

    // Create a variable emailRegex and store the Regex value
    setCustomerDetails({ ...customerDetails, email: formattedEmail[0] });
    if (formattedEmail[1]) {
      // setCustomerDetails(value);
      setIsEmailVerified(true);
      // Call enableContinueButton('Email')
      enableContinueButton('Email');
    } else {
      // If condition fails, set setIsEmailVerified as false and setDisableContinueButton(true)
      setIsEmailVerified(false);
      setDisableContinueButton(true);
    }
  }
  //  Functionf or handle input change
  function validInputs(event: any) {
    // Check if event.target.id is equals to SSN
    if (event.target.id === 'SSN') {
      // Create a variable lastFourssn and assign event.target.value.replace(/[^\d]/g,"")
      const validation: arrayvalidator = valid(
        event.target.value,
        'number'
      ) as arrayvalidator;
      // Inside the if checking the another condition if lastFourssn.length is equals to 4

      setCustomerDetails({ ...customerDetails, lastFourSsn: (validation[0].toString()).slice(-4) });

      console.log(validation, 'four');
      if (validation[1]) {
        // Set setIsLastFourSSNVerified as true
        setIsLastFourSSNVerified(true);
        // Call enableContinueButton('SSN')
        enableContinueButton('SSN');
      } else {
        // If condition fails, set setIsLastFourSSNVerified as false and set setDisableContinueButton as true
        setIsLastFourSSNVerified(false);
        setDisableContinueButton(true);
      }
    }
    console.log(customerDetails, 'cus');

    // Check if event.target.id is equals to 'FirstName'
    if (event.target.id === 'FirstName') {
      // Create a variable validation and calling the valid() and passing the event.target.value and 'name' as a parameter.
      const validation: arrayvalidator = valid(
        event.target.value,
        'name'
      ) as arrayvalidator;
      // Check if firstName is greater than 0 and firstName.trim().length is less than or equal to 30
      setCustomerDetails({ ...customerDetails, firstName: validation[0] });
      console.log(validation[1], 'valid check');

      if (validation[1]) {
        // Set setCustomerDetails(firstName)

        // Set setIsFirstNameVerified as true
        setIsFirstNameVerified(true);
        // Call enableContinueButton('FirstName')
        enableContinueButton('FirstName');
      } else {
        // If condition fails, set setCustomerDetails(firstName), set setIsFirstNameVerified as false and set setDisableContinueButton as true

        setIsFirstNameVerified(false);
        setDisableContinueButton(true);
      }
    }

    // Check if event.target.id is equals to 'LastName'
    if (event.target.id === 'LastName') {
      // Create a variable lastName and store event.target.value with replace (/[^A-Za-z' .,-]/ig, '')
      const validation: arrayvalidator = valid(
        event.target.value,
        'name'
      ) as arrayvalidator;
      setCustomerDetails({ ...customerDetails, lastName: validation[0] });
      // Check if lastName is greater than 0 and lastName.trim().length is less than or equal to 30
      if (validation[1]) {
        // Set setIsLastNameVerified as true
        setIsLastNameVerified(true);
        // Call enableContinueButton('LastName')
        enableContinueButton('LastName');
      } else {
        // If condition fails, set setCustomerDetails(lastName), set setIsLastNameVerified as false and set setDisableContinueButton as true
        // setCustomerDetails(lastName);
        setIsLastNameVerified(false);
        setDisableContinueButton(true);
      }
    }
  }
  // Function for OTP Confimr click
  const ConfirmButtonClickOTP = async () => {

    const payload = {
      enteredOtp: OTPValue,
      messageId: messageId,
      phoneNumber: customerDetails.unFormattedPhoneNumber,
    }
    setIsOTPOpen(false)
    setIsLookingUpOpen(true);

    const validateOtpResponse: any = await ValidateOTP(payload, customerDetails.authKey ? false : customerDetails.storeCode,
      customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode)
    setIsLookingUpOpen(false)
    setIsOTPOpen(true)
    debugger
    if (validateOtpResponse?.status == 200 && validateOtpResponse?.isValid == true && validateOtpResponse?.resendOtp == false && validateOtpResponse?.cacheDeleted == true) {
      setIsOTPOpen(false);
      setIsLookingUpOpen(true);
      setOTPValidationText('');
      history.push('/payfoneprefill');
    }
    else if (validateOtpResponse?.data?.errors[0]?.error?.status == 400 && validateOtpResponse?.data?.errors[0]?.error?.isValid == false && validateOtpResponse?.data?.errors[0]?.error?.resendOtp == false && validateOtpResponse?.data?.errors[0]?.error?.cacheDeleted == false) {

      setOTPValidationText('Please Enter the valid OTP')
    }
    else {

      setOTPValidationText('Please Resend the OTP')
    }
  }

  // Function for SendOtp service call
  async function OTPContinue() {
    setIsLookingUpOpen(true);
    debugger
    // Check if Authkey is not empty
    if (customerDetails.authKey) {
      const OTPResponse = await sendOTP(
        customerDetails.unFormattedPhoneNumber,
        customerDetails.authKey ? false : customerDetails.storeCode,
        customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode
      );
      console.log(OTPResponse, 'ress');

      // Check OTPResponse
      if (OTPResponse) {
        // Check if OTPResponse.smscode is not undefined
        if (OTPResponse && OTPResponse?.messageId && OTPResponse?.message == 'SUCCESS') {
          setIsLookingUpOpen(false);
          setIsOTPOpen(true);
          setMessageId(OTPResponse?.messageId);
          setActualOtp(OTPResponse?.smscode);
        }
        else if (OTPResponse && OTPResponse?.uniqueId && OTPResponse?.messageId == 'PLEASETRYAGAIN') {
          setIsLookingUpOpen(false);
          setMobileNoValidationText('Please Try Again');
          setDisableContinueButton(true);
        }
        else {
          setIsLookingUpOpen(false);
          setMobileNoValidationText('Please Enter Valid MobileNumber');
          setDisableContinueButton(true);
        }
      } else {
        history.push('/IdentityVerify?Status=Exception');
      }
    } else {


      const payload: any = {};
      payload.phoneNumber = customerDetails.unFormattedPhoneNumber;
      payload.emailAddress = customerDetails.email;
      payload.storeNumber = customerDetails.storeCode ? customerDetails.storeCode : customerDetails.storeNumber ? customerDetails.storeNumber : '';
      const updateAccessLinkRes = await updateAccessLink(payload, customerDetails.authKey ? false : customerDetails.storeCode,
        customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode);
      console.log(updateAccessLinkRes, 'response of update');
      if (updateAccessLinkRes?.data?.token) {

        setCustomerDetails({
          ...customerDetails,
          storeFlowAuthKey: updateAccessLinkRes.data.token,
        });

        const OTPResponse = await sendOTP(
          customerDetails.unFormattedPhoneNumber,
          customerDetails.authKey ? false : customerDetails.storeCode,
          customerDetails.authKey ? customerDetails.authKey : customerDetails.storeCode
        );
        console.log(OTPResponse, 'response of itp');
        if (OTPResponse && OTPResponse?.messageId && OTPResponse?.message == 'SUCCESS') {
          setIsLookingUpOpen(false);
          setIsOTPOpen(true);
          setMessageId(OTPResponse?.messageId);
          setActualOtp(OTPResponse?.smscode);
        }
        else if (OTPResponse && OTPResponse?.uniqueId && OTPResponse?.messageId == 'PLEASETRYAGAIN') {
          setIsLookingUpOpen(false);
          setMobileNoValidationText('Please Try Again');
          setDisableContinueButton(true);
        }
        else {
          setIsLookingUpOpen(false);
          setMobileNoValidationText('Please Enter Valid MobileNumber');
          setDisableContinueButton(true);
        }
      } else {
        // If Authkey condition fails, set setIsLookingUpOpen as true
        console.log('inside of the error');

        history.push('/IdentityVerify?status=Exception');
      }
    }
  }


  // Function for OTP Onchange
  function setOtpValue(e: any) {
    // Set setOTPValidationText as ''
    console.log(e.target.value, 'thiis value');
    setOTPValidationText('');

    // Create a variable OTP and assign e.target.value.replace(/[^\d]/g,"")
    const OTP = e.target.value.replace(/[^\d]/g, '');

    setOTPValue(OTP);
  }
  // Function for previous button click
  function basicPrevious() {
    if (customerDetails.EncodeAuthKey) {
      history.push(
        `/?AuthKey=${customerDetails.EncodeAuthKey}`
      );
    } else {
      history.push(
        `/?StoreCode=${customerDetails.EncodedStorecode}`
      );
    }
  }

  return (
    <Grid>
      <Grid>
        <DofHeaders />
      </Grid>
      {isLookingUpOpen ? (
        <LoaderPopup message={'Please wait...'} />
      ) : (
        <></>
      )}

      <Grid className={`${classes.bodycontainer}`}>
        <Grid>
          <Typography className={`${classes.forheading}`}>
            Let's look up your info! Let us do the work for you! We may be able
            to find and prefill your contact info.
          </Typography>
        </Grid>
        <Grid
          container
          md={12}
          spacing={2}
          style={{ marginTop: '29px', justifyContent: 'space-between' }}
        >
          <Grid item md={6} sm={12} xs={12}>
            <RACTextbox
              className={`${classes.labelmargintop}`}
              required={true}
              type="text"
              id="FirstName"
              inputlabel="First Name"
              minlength={1}
              maxlength={30}
              inputLabelClassname={`${classes.opensansbold}`}
              OnChange={(e) => validInputs(e)}
              value={customerDetails.firstName}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} style={{ marginRight: '-15px' }}>
            <RACTextbox
              className={`${classes.labelmargintop}`}
              required={true}
              type="text"
              id="LastName"
              inputlabel="Last Name"
              maxlength={30}
              inputLabelClassname={`${classes.opensansbold}`}
              inputTextBoxClassname={`${classes.forheight}`}
              OnChange={(e) => validInputs(e)}
              value={customerDetails.lastName}
            />
          </Grid>
        </Grid>

        <Grid className={`${classes.labelmargintop}`} md={12} sm={12}>
          <RACTextbox
            className={`${classes.labelmargintop}`}
            required={true}
            type="text"
            inputlabel="Mobile#"
            errorMessage={mobNoValidationText}
            inputLabelClassname={`${classes.opensansbold}`}
            OnChange={(e) => formatPhoneNumber(e.target.value)}
            value={customerDetails.mobileNumber}
            maxlength={14}
          />
        </Grid>

        <Grid md={12} className={`${classes.labelmargintop}`}>
          <RACTextbox
            className={`${classes.labelmargintop}`}
            required={true}
            type="text"
            inputlabel="Email"
            maxlength={50}
            OnChange={(e) => EmailValueVerify(e.target.value)}
            value={customerDetails.email}
            inputLabelClassname={`${classes.opensansbold}`}
            errorMessage={
              !isEmailVerified && customerDetails?.email?.length > 0
                ? 'Please enter valid email'
                : ''
            }
          />
        </Grid>

        <Grid>
          <RACModalCard
            isOpen={OTPOpen}
            closeIcon={true}
            onClose={() => setIsOTPOpen(false)}
            maxWidth={'xs'}
            title="Please enter the One Time Password sent to your mobile #"
            borderRadius={'15px'}
          >
            <RACTextbox
              inputlabel="OTP"
              type="text"
              value={OTPValue}
              required={true}
              errorMessage={otpValidationText}
              OnChange={(e) => setOtpValue(e)}
              maxlength={6}
            ></RACTextbox>
            <RACButton
              style={{
                float: 'right',
                marginTop: '30px',
                width: '130px',
                backgroundColor: '#2179FE',
                color: 'white',
                fontWeight: 'bold',
              }}
              disabled={OTPValue ? false : true}
              onClick={() => ConfirmButtonClickOTP()}
            >
              Confirm
            </RACButton>
            <RACButton
              style={{
                float: 'right',
                marginTop: '30px',
                width: '130px',
                backgroundColor: '#2179FE',
                marginRight: '20px',
                color: 'white',
                fontWeight: 'bold',
              }}
              onClick={() => OTPContinue()}
            >
              Resend OTP
            </RACButton>
          </RACModalCard>
        </Grid>

        <Grid style={{ marginTop: '15px' }}>
          <RACTextbox
            className={`${classes.labelmargintop}`}
            required={false}
            type="text"
            id="SSN"
            inputlabel="Last 4 SSN "
            maxlength={4}
            OnChange={(e) => validInputs(e)}
            value={customerDetails.lastFourSsn}
            inputLabelClassname={`${classes.opensansbold}`}
          />
        </Grid>
        <Grid container className={classes.topMargin}>
          <Grid item>
            <Typography className={classes.textAlignJustify}>
              By providing your mobile phone number and clicking "Continue", you agree Rent-A-Center ("RAC") and its agents may deliver calls and text messages regarding your account using an automated system for the selection or dialing of telephone numbers. You understand that RAC may send you text messages regarding important account information, such as transaction details or servicing information. You also understand that by clicking "Continue" you are agreeing to the Privacy Policy, Terms of Use and Arbitration Agreement, Third Party Terms, and ESIGN disclosure, and are certifying that you are authorized to provide this consent. We will send a confirmation text upon receipt of your information.
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: '25px' }}>
            <Grid className={classes.textAlignJustify}>
              <Typography>
                <span style={{
                  position: 'relative',
                  marginRight: '20px'
                }}>
                  <input style={{
                    position: 'absolute',
                    top: '-4px'
                  }} type='checkbox' name='basicInfo'

                    onClick={() => setCustomerDetails({ ...customerDetails, phoneSolicitationAllowed: !customerDetails.phoneSolicitationAllowed })}
                  />
                </span>
                By providing your mobile phone number and checking this box, you agree Rent-A- Center ("RAC") and its agents may deliver automated calls and text messages regarding RAC goods and services or offers to you at the mobile number you provide using an automated system for the selection or dialing of telephone numbers. You understand that you are not required to consent to receive automated marketing calls and text messages in order to obtain goods or services from RAC or open a RAC account. Message frequency varies. Message and data rates may apply. When you receive a message reply HELP for help or STOP to opt out.
              </Typography>
            </Grid>
            <Typography className={classes.textAlignJustify}>
              <br />
              By clicking "Continue" you are agreeing to the Privacy Policy, Terms of Use and Arbitration Agreement, Third Party Terms, and ESIGN disclosure, and are certifying that you are authorized to provide this consent. We will send a confirmation text upon receipt of your information.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          className={`${classes.topmargin}`}
          container
          md={12}
          sm={12}
          xs={12}
        >
          <Grid item md={6} sm={6} xs={6}>
            <RACButton

              onClick={() => basicPrevious()}
              color="secondary"
              variant="outlined"
              style={{ float: 'left' }}
            >
              Previous
            </RACButton>
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <RACButton

              color="primary"
              variant="contained"
              style={{ float: 'right' }}
              disabled={continueDisable}
              onClick={OTPContinue}
            >
              Continue
            </RACButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
