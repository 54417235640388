/* eslint-disable */

export const ProductType = 'RPDPLACEHOLDER';

export enum ProductPermissions {
  PLACEHOLDER = 'PLACEHOLDER',
}

import { dropdown } from '../interfaces/interfaces';

export const numericRegex = /[^\d]/g;
export const alphabeticRegex = /[^A-Za-z' .,-]/gi;
export const onlyAlphabeticRegex = /[^a-zA-Z ]/g;
export const alphaNumericRegex = /[^0-9a-zA-Z# .,-]/gi;
export const emailFormat =
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
export const dropOptionsPayload = {
  references: [
    'CALL_TIME',
    'GOVERNMENT_ID_TYPE',
    'RELATIONSHIP_TYPE',
    'EMPLOYER_PAYSCHEDULE',
    'DE_EXCEPTION_REASON',
  ],
  status: 'Y',
};

export const howLongOptions: dropdown[] = [
  {
    value: '',
    label: 'Select',
  },
  {
    value: '1',
    label: '0 to 1 year',
  },
  {
    value: '2',
    label: '1 to 5 years',
  },
  {
    value: '3',
    label: '5+ years',
  },
];
