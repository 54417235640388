/* eslint-disable */

export enum AppRoute {
  startingPage = '/',
  payfoneprefill = '/payfoneprefill',
  basicInfo = '/basicinfo',
  employerInfo = '/employer',
  residenceInfo = '/residence',
  referenceInfo = '/reference',
  reviewInfo = '/reviewinfo',
  ApprovalPage = '/ApprovalPage',
  identifyVerify = '/IdentityVerify',
  userInfo = '/userInfo',
}
