/* eslint-disable */
import {
  Grid,
  RACTextbox,
  RACSelect,
  RACButton,
  Typography,
} from '@rentacenter/racstrap';

import { DofCssClasses } from '../styles/dofStyles';
import { dofContextValue } from '../context/DofContext';
import React, { useContext, useEffect, useState } from 'react';
import { Validator } from '../utils/utils';
import DofHeaders from './DofHeaders';
import { useHistory } from 'react-router-dom';
import HeadersStyles from './HeadersStyles';
import { VerifyInputValues } from '../interfaces/interfaces';

export default function BasicInfo() {
  const {
    customerDetails,
    setCustomerDetails,
    stateOptions,
    bestTimeToCallOptions,
    govtIdTypeOptions,
    isVerified,
    setIsVerified,
  } = useContext(dofContextValue);
  const [isGovtNumOnFocus, setisGovtNumOnFocus] = useState<boolean>(false);


  useEffect(() => {
    console.log('Customer Details in BasicInfo', customerDetails);
  }, [customerDetails])

  const dofClassName: any = DofCssClasses();
  const history = useHistory();

  //  Function for handleInput change
  const handleInput = (e: any, opt?: string) => {
    if (opt) {
      if (opt === 'call_time') {
        const response = Validator(e.target.value, 'dropdown');
        setCustomerDetails({ ...customerDetails, bestTimeToCall: response[0] });
        setIsVerified((event: VerifyInputValues) => ({
          ...event,
          basicCallTime: response[1],
        }));
      } else if (opt === 'govt_id_type') {
        const response = Validator(e.target.value, 'dropdown');
        if (e.target.value == 'NONE') {
          setCustomerDetails({ ...customerDetails, govtId: '', govtIdState: '', govtIdType: response[0] });
        }
        else {
          setCustomerDetails({ ...customerDetails, govtIdType: response[0] });
          setIsVerified((event: VerifyInputValues) => ({
            ...event,
            govtIdType: response[1],
          }));
        }



      } else if (opt === 'govt_id_state') {
        const response = Validator(e.target.value, 'dropdown');
        setCustomerDetails({ ...customerDetails, govtIdState: response[0] });
        setIsVerified((event: VerifyInputValues) => ({
          ...event,
          govtIdState: response[1],
        }));
      }
    }
    else if (e.target.name === 'govt_id') {
      setCustomerDetails({ ...customerDetails, govtId: e.target.value.replace(/[^A-Z-'., a-z0-9]/gi, '') });
    }
  };

  //  Handle continue function
  const handleContinueBtn = () => {
    history.push('/employer');
  };

  return (
    <Grid>
      <DofHeaders />
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={`${dofClassName.floatCenter}`}
      >
        <Grid container lg={6} md={8} sm={10} xs={10}>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.displayFlex} ${dofClassName.floatCenter}`}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <HeadersStyles
                BasicInfoStatus={true}
                EmployerStatus={false}
                ResidenceStatus={false}
                ReferenceStatus={false}
              />
            </Grid>

            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.floatLeft} ${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <Typography className={`${dofClassName.existCustTitle}`}>
                {' '}
                Please enter your basic info:{' '}
              </Typography>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <RACTextbox
                inputlabel="Mobile #"
                name="phone_number"
                required={true}
                value={`(${customerDetails.unFormattedPhoneNumber.slice(
                  0,
                  3
                )}) ${customerDetails.unFormattedPhoneNumber.slice(
                  3,
                  6
                )}-${customerDetails.unFormattedPhoneNumber.slice(6, 10)}`}
                disabled={true}
                inputLabelClassname={dofClassName.marginBottomforInput}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <div className={dofClassName.inputBoxHeightForSelect}>
                <RACSelect
                  inputLabel="Best Time to Call"
                  name="call_time"
                  required={true}
                  defaultValue={customerDetails.bestTimeToCall}
                  options={bestTimeToCallOptions}
                  onChange={(e: any) => {
                    handleInput(e, 'call_time');
                  }}
                  inputLabelClassName={dofClassName.marginBottomforInput}
                />
              </div>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <div className={dofClassName.inputBoxHeightForSelect}>
                <RACSelect
                  inputLabel="Goct. ID Type"
                  name="govt_id_type"
                  required={true}
                  defaultValue={customerDetails.govtIdType}
                  options={govtIdTypeOptions}
                  onChange={(e: any) => {
                    handleInput(e, 'govt_id_type');
                  }}
                  inputLabelClassName={dofClassName.marginBottomforInput}
                />
              </div>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <RACTextbox
                isCurrency={false}
                type={'text'}
                inputlabel="Govt. ID #"
                name="govt_id"
                required={true}
                OnChange={(e: any) => handleInput(e)}
                maxlength={30}
                onFocus={() => setisGovtNumOnFocus(true)}
                Onblur={() => setisGovtNumOnFocus(false)}
                value={
                  customerDetails?.govtId !==
                    undefined &&
                    customerDetails?.govtId !== null &&
                    customerDetails?.govtId !== ''
                    ? customerDetails?.govtId.length >
                      4 && isGovtNumOnFocus !== true
                      ?
                      customerDetails?.govtId
                        .substring(
                          0,
                          customerDetails?.govtId
                            .length - 4
                        )
                        .replace(/[A-Za-z0-9.,-]/g, '*') +
                      customerDetails?.govtId.substring(
                        customerDetails?.govtId
                          .length - 4
                      )
                      : customerDetails?.govtId
                    : ''
                }

                inputLabelClassname={dofClassName.marginBottomforInput}
                disabled={customerDetails.govtIdType == 'NONE' ? true : false}
                className={`${dofClassName.inputBoxHeight}`}


              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <div className={dofClassName.inputBoxHeightForSelect}>
                <RACSelect
                  inputLabel="Govt. ID State"
                  name="govt_id_state"
                  required={true}
                  defaultValue={customerDetails.govtIdState}
                  isDisabled={customerDetails.govtIdType == 'NONE' ? true : false}
                  options={stateOptions}
                  onChange={(e: any) => {
                    handleInput(e, 'govt_id_state');
                  }}
                  inputLabelClassName={dofClassName.marginBottomforInput}
                />
              </div>
            </Grid>
            <Grid
              container
              spacing={2}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.topMargin} ${dofClassName.floatRight} ${dofClassName.floatRight}`}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className={dofClassName.textAlignRight}
              >
                <RACButton
                  onClick={() => {
                    handleContinueBtn();
                  }}
                  disabled={
                    !(
                      (isVerified.basicCallTime &&
                        isVerified.govtIdType &&
                        isVerified.govtId &&
                        isVerified.govtIdState) || (
                        customerDetails.bestTimeToCall != '' && customerDetails.govtIdType != '' && customerDetails?.govtId && customerDetails.govtIdState != ''
                      ) || (
                        (customerDetails.govtIdType == 'NONE' ? (isVerified.basicCallTime || customerDetails.bestTimeToCall) : false)
                      )
                    )
                  }
                  color="primary"
                  variant="contained"
                >
                  Continue
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
          {/* </Card> */}
        </Grid>
      </Grid>
    </Grid>
  );
}
