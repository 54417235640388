/* eslint-disable */
/* eslint-disable sonarjs/cognitive-complexity */
import {
  Grid,
  RACTextbox,
  RACSelect,
  RACButton,
  Typography,
} from '@rentacenter/racstrap';

import { DofCssClasses } from '../styles/dofStyles';
import { dofContextValue } from '../context/DofContext';
import React, { useContext, useState } from 'react';
import { Validator } from '../utils/utils';
import { useHistory } from 'react-router-dom';
import DofHeaders from './DofHeaders';
import HeadersStyles from './HeadersStyles';
import { VerifyInputValues } from '../interfaces/interfaces';

export default function Reference() {
  const {
    referenceDetails,
    setReferenceDetails,
    stateOptions,
    relationshipOptions,
    bestTimeToCallOptions,
    isVerified,
    setIsVerified,
    formatedMobileNumber,
    setFormatedMobileNumber
  } = useContext(dofContextValue);

  const history = useHistory();
  const dofClassName: any = DofCssClasses();


  // Function for handle onchange
  const handleInput = (e: any, opt?: string) => {
    if (opt) {
      if (opt === 'call_time') {
        const response = Validator(e.target.value, 'dropdown');
        setReferenceDetails({
          ...referenceDetails,
          refBestTimeToCall: response[0],
        });
      } else if (opt === 'relationship') {
        const response = Validator(e.target.value, 'dropdown');
        setReferenceDetails({
          ...referenceDetails,
          relationShipType: response[0],
        });
        setIsVerified((event: VerifyInputValues) => ({
          ...event,
          refRelation: response[1],
        }));
      } else if (opt === 'state') {
        const response = Validator(e.target.value, 'dropdown');
        setReferenceDetails({
          ...referenceDetails,
          refState: response[0],
        });
      }
    } else {
      if (e.target.name === 'reference_first_name') {
        const response = Validator(e.target.value, 'name');
        setReferenceDetails({
          ...referenceDetails,
          refFirstName: response[0],
        });
        setIsVerified((event: VerifyInputValues) => ({
          ...event,
          refFirstName: response[1],
        }));
      } else if (e.target.name === 'reference_last_name') {
        const response = Validator(e.target.value, 'name');
        setReferenceDetails({
          ...referenceDetails,
          refLastName: response[0],
        });
        setIsVerified((event: VerifyInputValues) => ({
          ...event,
          refLastName: response[1],
        }));
      } else if (e.target.name === 'reference_phone') {
        const response = Validator(e.target.value, 'phoneNumber');
        setReferenceDetails({
          ...referenceDetails,
          refPhoneNumber: response[0],
        });
        setIsVerified((event: VerifyInputValues) => ({
          ...event,
          refPhoneNumber: response[1],
        }));

        // setIsPhoneVerified(response[1])
        setFormatedMobileNumber(response[2]);
      } else if (e.target.name === 'address_line_1') {
        const response = Validator(e.target.value, 'address');
        setReferenceDetails({
          ...referenceDetails,
          refAddress1: response[0],
        });
      } else if (e.target.name === 'address_line_2') {
        const response = Validator(e.target.value, 'address');
        setReferenceDetails({
          ...referenceDetails,
          refAddress2: response[0],
        });
      } else if (e.target.name === 'ZIPCode') {
        const response = Validator(e.target.value, 'zipcode');
        setReferenceDetails({
          ...referenceDetails,
          refZipCode: response[0],
        });
        setIsVerified((event: VerifyInputValues) => ({
          ...event,
          refZipOptional: response[1]
        }));

      } else if (e.target.name === 'city') {
        const response = Validator(e.target.value, 'onlyAlphabet');
        setReferenceDetails({
          ...referenceDetails,
          refCity: response[0],
        });
      }
    }
  };
  // Function for  continue button click
  const handleContinueBtn = () => {
    history.push('/reviewinfo');
  };
  // Function for  previous button click

  const handlePreviousBtn = () => {
    history.push('/residence');
  };

  return (
    <Grid>
      <DofHeaders />
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={`${dofClassName.floatCenter}`}
      >
        <Grid container lg={6} md={8} sm={10} xs={10}>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`${dofClassName.displayFlex} ${dofClassName.floatCenter}`}
          >
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <HeadersStyles
                BasicInfoStatus={true}
                EmployerStatus={true}
                ResidenceStatus={true}
                ReferenceStatus={true}
              />
            </Grid>

            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.floatLeft} ${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <Typography className={`${dofClassName.existCustTitle}`}>
                {' '}
                Please add a parent or a relative as a reference{' '}
              </Typography>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <RACTextbox
                inputlabel="Parent / Relative First Name"
                name="reference_first_name"
                required={true}
                value={referenceDetails.refFirstName}
                OnChange={(e) => {
                  handleInput(e);
                }}
                maxlength={30}
                inputLabelClassname={dofClassName.marginBottomforInput}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <RACTextbox
                inputlabel="Parent / Relative Last Name"
                name="reference_last_name"
                maxlength={30}
                required={true}
                value={referenceDetails.refLastName}
                OnChange={(e) => {
                  handleInput(e);
                }}
                inputLabelClassname={dofClassName.marginBottomforInput}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <RACTextbox
                inputlabel="Phone #"
                name="reference_phone"
                required={true}
                value={formatedMobileNumber}
                maxlength={14}
                OnChange={(e) => {
                  handleInput(e);
                }}
                inputLabelClassname={dofClassName.marginBottomforInput}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <div className={dofClassName.inputBoxHeightForSelect}>
                <RACSelect
                  inputLabel="Best Time to Call"
                  name="call_time"
                  required={false}
                  defaultValue={referenceDetails.refBestTimeToCall}
                  options={bestTimeToCallOptions}
                  onChange={(e: any) => {
                    handleInput(e, 'call_time');
                  }}
                  inputLabelClassName={dofClassName.marginBottomforInput}
                />
              </div>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}
            >
              <div className={dofClassName.inputBoxHeightForSelect}>
                <RACSelect
                  inputLabel="Relationship"
                  name="relationship"
                  required={true}
                  defaultValue={referenceDetails.relationShipType}
                  options={relationshipOptions}
                  onChange={(e: any) => {
                    handleInput(e, 'relationship');
                  }}
                  inputLabelClassName={dofClassName.marginBottomforInput}
                />
              </div>
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight}`}
            >
              <RACTextbox
                inputlabel="Address Line 1"
                name="address_line_1"
                required={false}
                value={referenceDetails.refAddress1}
                maxlength={30}
                type="text"
                OnChange={(e: any) => handleInput(e)}
                inputLabelClassname={dofClassName.marginBottomforInput}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>

            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.marginLeftRight} ${dofClassName.marginTop16px}`}
            >
              <RACTextbox
                inputlabel="Address Line 2"
                name="address_line_2"
                required={false}
                value={referenceDetails.refAddress2}
                maxlength={30}
                type="text"
                OnChange={(e: any) => handleInput(e)}
                inputLabelClassname={dofClassName.marginBottomforInput}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>

            <Grid
              container
              spacing={2}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginTop16px}`}
            >
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <RACTextbox
                  inputlabel="Zip Code"
                  name="ZIPCode"
                  required={false}
                  value={referenceDetails.refZipCode}
                  maxlength={10}
                  type="text"
                  OnChange={(e: any) => handleInput(e)}
                  inputLabelClassname={dofClassName.marginBottomforInput}
                  className={`${dofClassName.inputBoxHeight}`}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <RACTextbox
                  inputlabel="City"
                  name="city"
                  required={false}
                  value={referenceDetails.refCity}
                  maxlength={30}
                  type="text"
                  OnChange={(e: any) => handleInput(e)}
                  inputLabelClassname={dofClassName.marginBottomforInput}
                  className={`${dofClassName.inputBoxHeight}`}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginTop16px}`}
            >
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={dofClassName.inputBoxHeightForSelect}>
                  <RACSelect
                    inputLabel="State"
                    name="state"
                    required={false}
                    defaultValue={referenceDetails.refState}
                    options={stateOptions}
                    onChange={(e: any) => {
                      handleInput(e, 'state');
                    }}
                    inputLabelClassName={dofClassName.marginBottomforInput}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={`${dofClassName.topMargin} ${dofClassName.spaceBetween}`}
            >
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className={dofClassName.textAlignLeft}
              >
                <RACButton
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    handlePreviousBtn();
                  }}
                >
                  Previous
                </RACButton>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                xs={6}
                className={dofClassName.textAlignRight}
              >
                <RACButton
                  onClick={() => {
                    handleContinueBtn();
                  }}
                  color="primary"
                  variant="contained"
                  disabled={
                    !(
                      (isVerified.refFirstName &&
                        isVerified.refLastName &&
                        isVerified.refPhoneNumber &&
                        isVerified.refRelation &&
                        isVerified.refZipOptional) || (referenceDetails.refFirstName && referenceDetails.refLastName && referenceDetails.relationShipType && referenceDetails.refPhoneNumber)
                    )
                  }
                >
                  Continue
                </RACButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
