/*eslint-disable*/
import { AxiosResponse } from 'axios';
import { APIError } from '../types/types';
import {
  alphabeticRegex,
  numericRegex,
  alphaNumericRegex,
  emailFormat,
  onlyAlphabeticRegex,
} from '../constants/constants';
import moment from 'moment';

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};

export function Validator(
  value: string,
  type: string
): [string, boolean, string] {
  let originalValue = '';
  let isValid = false;
  let optionalValue = '';

  switch (type) {
    case 'name':
      originalValue = value.replace(alphabeticRegex, '');
      isValid = originalValue.length > 0;
      break;
    case 'email':
      console.log(emailFormat.test(value.trim()), 'rege');
      if (emailFormat.test(value.trim())) {
        isValid = true;
      } else {
        isValid = false;
      }
      originalValue = value;
      return [originalValue, isValid, ''];
    case 'number':
      originalValue = value.replace(numericRegex, '');
      isValid = originalValue.length >= 1;
      break;

    case 'ssn4':
      originalValue = value.replace(numericRegex, '');
      isValid = originalValue.length == 9;
      break;

    case 'phoneNumber':
      const cleaned = value.replace(numericRegex, '').trim();
      originalValue = cleaned;
      if (cleaned.length < 4) optionalValue = cleaned;
      // if phoneNumberLength is greater than 4 and less the 7 we start to return
      // the formatted number
      else if (cleaned.length < 7) {
        optionalValue = `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
      }
      // bit of formatting and return it.
      else if (cleaned.length <= 10)
        optionalValue = `(${cleaned.slice(0, 3)}) ${cleaned.slice(
          3,
          6
        )}-${cleaned.slice(6, 10)}`;
      isValid = cleaned.length === 10;
      break;

    case 'onlyAlphabet':
      originalValue = value.replace(onlyAlphabeticRegex, '');
      isValid = originalValue.length >= 1;
      break;

    case 'zipcode':
      const cleanedZip = value.replace(numericRegex, '');
      isValid = cleanedZip.length === 5 || cleanedZip.length === 9;
      originalValue =
        cleanedZip.length > 5
          ? cleanedZip.slice(0, 5) + '-' + cleanedZip.slice(5)
          : cleanedZip;
      break;
    case 'ssn':
      originalValue = value.replace(numericRegex, '');
      isValid = originalValue.length === 9;
      break;
    case 'address':
      originalValue = value.replace(alphaNumericRegex, '');
      isValid = originalValue.length > 0;
      break;
    case 'dropdown':
      originalValue = value;
      isValid = originalValue !== '';
      break;

    case 'DOB':
      const format = moment(value).format('MM/DD/YYYY');
      originalValue = value.replace(format, '');
      const stDate = moment(value, 'YYYY-MM-DD');
      const lt18 = moment(moment().subtract(18, 'years'), 'YYYY-MM-DD');
      const lt110 = moment(moment().subtract(110, 'years'), 'YYYY-MM-DD');
      const lt18Res = lt18.diff(stDate, 'days');
      const lt110Res = lt110.diff(stDate, 'days');
      isValid = lt18Res >= 0 && lt110Res < 0;
      break;
    case 'date':
      const dateFormat = moment(value).format('MM/DD/YYYY');
      originalValue = value.replace(dateFormat, '');
      isValid = true;
      break;
    default:
      break;
  }

  return [originalValue, isValid, optionalValue];
}
