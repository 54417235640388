/* eslint-disable */
/* eslint-disable no-console */
import React, { useContext } from 'react';
import { dofContextValue } from '../context/DofContext';
import DofHeader from './DofHeaders';
import { Grid, Card, CardContent, Typography } from '@rentacenter/racstrap';
import { DofCssClasses } from '../styles/dofStyles';
export function ApprovalPage() {
  const classes: any = DofCssClasses();
  const { customerDetails } = useContext(dofContextValue);

  return (
    <Grid>
      <DofHeader />
      <Grid
        container
        md={12}
        sm={12}
        xs={12}
        className={`${classes.forjustify}`}
      >
        <Grid item md={6} sm={12} xs={12} className={`${classes.forspace}`}>
          <Card>
            <CardContent>
              {customerDetails.approvalResponse == '1' ? (
                <Grid>
                  <Typography
                    className={` ${classes.forblue} ${classes.forfont} ${classes.forfontsize} ${classes.mt40}`}
                  >
                    Thank you for completing your profile
                  </Typography>
                  <Typography
                    className={`${classes.forfont} ${classes.foralign} ${classes.forfontsize}`}
                  >
                    Congratulations! You have been
                  </Typography>
                  <Typography
                    className={`${classes.forfont} ${classes.forfontsize}`}
                  >
                    approved for
                  </Typography>
                  <Grid style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '11px'
                  }}>
                    <span
                      style={{
                        fontSize: '15px',
                        fontFamily: 'OpenSans-bold',
                        marginRight: '9px',
                        marginTop: '27px',
                        color: '#0dcaf0',
                      }}
                    >$</span>
                    <Typography className={` ${classes.totalapprovalamount} `}>
                      {' '}
                      {customerDetails.totalApprovalAmount ? Number(customerDetails.totalApprovalAmount).toFixed(2) : '0.00'}
                    </Typography>
                  </Grid>
                  {customerDetails.weeklyApprovalAmount != '' ? (
                    <Grid
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '11px'
                      }}
                    >
                      <Typography className={`${classes.forweeklyapproval}`}>
                        Weekly Approval Amount:{' '}
                      </Typography>
                      <span
                        style={{
                          fontSize: '11px',
                          fontFamily: 'OpenSans-bold',
                          marginTop: '19px',
                          marginRight: '7px',
                          marginLeft: '10px',
                          color: '#0dcaf0',
                        }}
                      >$</span>
                      <Typography
                        style={{
                          fontSize: '16px',
                          color: '#0dcaf0',
                          fontFamily: 'OpenSans-bold',
                          marginTop: '13px',
                        }}
                      >
                        {' '}
                        {customerDetails.weeklyApprovalAmount ? Number(customerDetails.weeklyApprovalAmount).toFixed(2) : '0.00'}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              ) : customerDetails.approvalResponse == '4' || customerDetails.approvalResponse == '5' ? (
                <Grid>
                  <Typography
                    className={` ${classes.forblue} ${classes.forfont} ${classes.forfontsize} ${classes.mt40}`}
                  >
                    Thank you for completing your profile
                  </Typography>
                  <Typography
                    className={`${classes.forfont} ${classes.foralign} ${classes.forfontsize}`}
                  >
                    Congratulations! You have been
                  </Typography>
                  <Typography
                    className={`${classes.forfont} ${classes.forfontsize}`}
                  >
                    Pre-Approved for
                  </Typography>
                  <Grid style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '11px'
                  }}>
                    <span
                      style={{
                        fontSize: '15px',
                        fontFamily: 'OpenSans-bold',
                        marginRight: '9px',
                        marginTop: '27px',
                        color: '#0dcaf0',
                      }}
                    >$</span>
                    <Typography className={` ${classes.totalapprovalamount} `}>
                      {' '}
                      {customerDetails.totalApprovalAmount ? Number(customerDetails.totalApprovalAmount).toFixed(2) : '0.00'}
                    </Typography>
                  </Grid>
                  {customerDetails.weeklyApprovalAmount != '' ? (
                    <Grid
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '11px'
                      }}
                    >
                      <Typography className={`${classes.forweeklyapproval}`}>
                        Weekly Approval Amount:{' '}
                      </Typography>
                      <span
                        style={{
                          fontSize: '11px',
                          fontFamily: 'OpenSans-bold',
                          marginTop: '19px',
                          marginRight: '7px',
                          marginLeft: '10px',
                          color: '#0dcaf0',
                        }}
                      >$</span>
                      <Typography
                        style={{
                          fontSize: '16px',
                          color: '#0dcaf0',
                          fontFamily: 'OpenSans-bold',
                          marginTop: '13px',
                        }}
                      >
                        {' '}
                        {customerDetails.weeklyApprovalAmount ? Number(customerDetails.weeklyApprovalAmount).toFixed(2) : '0.00'}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Grid>
              ) : (
                <Grid>
                  <Typography
                    variant="h5"
                    className={`${classes.textHeading}`}
                  >
                    Thank you for completing your profile
                  </Typography>
                  <Typography className={`${classes.mt40} ${classes.subHeading}`}>
                    Our Coworker will finish the final step to get you your
                    product
                  </Typography>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}
