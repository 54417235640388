/* eslint-disable */
import {
  Grid,
  RACTextbox,
  RACSelect,
  RACButton,
  Typography
} from '@rentacenter/racstrap'

import { DofCssClasses } from './../styles/dofStyles'
import { dofContextValue } from './../context/DofContext'
import { useContext } from 'react'
import { howLongOptions } from './../constants/constants'
import { EmployerDetails, VerifyInputValues } from './../interfaces/interfaces'
import { Validator } from './../utils/utils'
import { useHistory } from 'react-router-dom'
import HeadersStyles from './HeadersStyles'
import DofHeaders from './DofHeaders'


export default function Employer() {
  const history = useHistory()
  let {
    employerDetails, setEmployerDetails,
    stateOptions,
    paydayFrequenchOptions,
    dayPaidOption1,
    dayPaidOption2,
    dayPaidOption3, setIsPrevious,
    customerDetails,
    isVerified, setIsVerified,
    formatMobileNumber, setFormatMobileNumber,
    dayPaidOption,
    setDayPaidOption
  } = useContext(dofContextValue)

  const dofClassName: any = DofCssClasses()

  //  Functionf for binding the DapPaidOut options

  const buildDayPaidOptions = (value: string) => {

    if (value === "WK" || value === "BIWK") {
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaidDisabled: false }))
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaid: false }))

      setDayPaidOption(dayPaidOption1)
      setEmployerDetails((event: EmployerDetails) => ({ ...event, dayPaid: "" }))
    } else if (value === "SEMI") {
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaidDisabled: false }))
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaid: false }))

      setDayPaidOption(dayPaidOption3)
      setEmployerDetails((event: EmployerDetails) => ({ ...event, dayPaid: "" }))
    } else if (value === "MON") {
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaidDisabled: false }))
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaid: false }))

      setDayPaidOption(dayPaidOption2)
      setEmployerDetails((event: EmployerDetails) => ({ ...event, dayPaid: "" }))
    } else {
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaidDisabled: true }))
      setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaid: true }))

      setDayPaidOption([{ value: '', label: '' }])
    }
  }

  const handleInput = (e: any, opt?: string) => {
    console.log("eeee", e.target);
    console.log("eeeename", e.target.name);
    if (opt) {
      if (opt === "payday_frequency") {
        const response = Validator(e.target.value, "dropdown")
        setEmployerDetails({
          ...employerDetails,
          paydayFrequency: response[0]
        })
        setIsVerified((event: VerifyInputValues) => ({ ...event, PayDayFrequency: response[1] }))
        buildDayPaidOptions(e.target.value)
      } else if (opt === "how_long") {
        const response = Validator(e.target.value, "dropdown")
        setEmployerDetails({
          ...employerDetails,
          howLong: response[0]
        })
      } else if (opt === "day_paid") {
        const response = Validator(e.target.value, "dropdown")
        setEmployerDetails((event: EmployerDetails) => ({ ...event, dayPaid: response[0] }))
        setIsVerified((event: VerifyInputValues) => ({ ...event, dayPaid: response[1] }))
      } else if (opt === "state") {
        const response = Validator(e.target.value, "dropdown")
        setEmployerDetails({
          ...employerDetails,
          empState: response[0]
        })
      }
    }
    else {
      if (e.target.name === "company_name") {
        const response = Validator(e.target.value, "address")
        setEmployerDetails({
          ...employerDetails,
          companyName: response[0]
        })
        setIsVerified((event: VerifyInputValues) => ({ ...event, companyName: response[1] }))
      }
      else if (e.target.name === "employer_phone") {

        const response = Validator(e.target.value, "phoneNumber")
        setEmployerDetails({
          ...employerDetails,
          employerPhone: response[0]
        })
        setFormatMobileNumber(response[2]);
        setIsVerified((event: VerifyInputValues) => ({ ...event, employerPhone: response[1] }))

      }
      else if (e.target.name === "take_home_pay") {
        setEmployerDetails({
          ...employerDetails,
          takeHomePay: e.target.value
        })
      }
      else if (e.target.name === "address_line_1") {
        const response = Validator(e.target.value, "address")
        setEmployerDetails({
          ...employerDetails,
          empAddress1: response[0]
        })
      }
      else if (e.target.name === "address_line_2") {
        const response = Validator(e.target.value, "address")
        setEmployerDetails({
          ...employerDetails,
          empAddress2: response[0]
        })
      }
      else if (e.target.name === "ZIPCode") {
        const response = Validator(e.target.value, "zipcode")
        setEmployerDetails({
          ...employerDetails,
          empZipCode: response[0]
        })
        setIsVerified((event: VerifyInputValues) => ({ ...event, zipCodeEmployer: response[1] }))
      }
      else if (e.target.name === "city") {
        const response = Validator(e.target.value, "onlyAlphabet")
        setEmployerDetails({
          ...employerDetails,
          empCity: response[0]
        })
      }
    }
  }

  const handleContinueBtn = () => {
    if (customerDetails?.approvalResponse == '1') {
      history.push('/reviewinfo');
    }
    else {
      history.push("/residence")
    }
  }

  const handlePreviousBtn = () => {
    setIsPrevious(true);
    history.push("/basicinfo")
  }

  return (
    <Grid>
      <DofHeaders />
      <Grid container lg={12} md={12} sm={12} xs={12} className={`${dofClassName.floatCenter}`}>
        <Grid container lg={6} md={8} sm={10} xs={10}>

          <Grid container lg={12} md={12} sm={12} xs={12} className={`${dofClassName.displayFlex} ${dofClassName.floatCenter}`}>

            <Grid container lg={12} md={12} sm={12} xs={12} className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}>
              <HeadersStyles
                BasicInfoStatus={true}
                EmployerStatus={true}
                ResidenceStatus={false}
                ReferenceStatus={false}
              />
            </Grid>

            <Grid container lg={12} md={12} sm={12} xs={12} className={`${dofClassName.floatLeft} ${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}>
              <Typography className={`${dofClassName.existCustTitle}`}> Please give us some information about your source of income </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className={`${dofClassName.marginLeftRight} ${dofClassName.marginInputFields}`}>
              <RACTextbox
                inputlabel='Company Name'
                name='company_name'
                required={true}
                value={employerDetails.companyName}
                maxlength={30}
                disabled={false}
                type='text'
                OnChange={(e: any) => handleInput(e)}
                inputLabelClassname={dofClassName.marginBottomforInput1}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>


            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginTop8px}`}>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={dofClassName.inputBoxHeightForSelect}>
                  <RACSelect
                    inputLabel="How Long?"
                    name="how_long"
                    required={false}
                    defaultValue={employerDetails.howLong}
                    options={howLongOptions}
                    onChange={(e: any) => { handleInput(e, 'how_long') }}
                    inputLabelClassName={dofClassName.marginBottomforInput1}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <RACTextbox
                  inputlabel='Employer Phone #'
                  name='employer_phone'
                  required={true}
                  value={formatMobileNumber}
                  maxlength={14}
                  type='text'
                  OnChange={(e: any) => handleInput(e)}
                  inputLabelClassname={dofClassName.marginBottomforInput1}
                  className={`${dofClassName.inputBoxHeight}`}
                />
              </Grid>

            </Grid>

            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12}>
              <Typography className={`${dofClassName.existCustHead} ${dofClassName.marginLeftRight}`}>
                Income Verification
              </Typography>
            </Grid>

            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginTop8px}`}>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={dofClassName.inputBoxHeightForSelect}>
                  <RACSelect
                    inputLabel="Payday Frequency"
                    name="payday_frequency"
                    required={true}
                    defaultValue={employerDetails.paydayFrequency}
                    options={paydayFrequenchOptions}
                    onChange={(e: any) => { handleInput(e, "payday_frequency") }}
                    inputLabelClassName={dofClassName.marginBottomforInput1}
                  />
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={dofClassName.inputBoxHeightForSelect}>
                  <RACSelect
                    inputLabel="Day Paid"
                    name="day_paid"
                    required={!isVerified.dayPaidDisabled}
                    defaultValue={employerDetails.dayPaid}
                    options={dayPaidOption}
                    isDisabled={isVerified.dayPaidDisabled}
                    onChange={(e: any) => { handleInput(e, "day_paid") }}
                    inputLabelClassName={dofClassName.marginBottomforInput1}
                  />
                </div>
              </Grid>

            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className={`${dofClassName.marginLeftRight} ${dofClassName.marginTop16px}`}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <RACTextbox
                  isCurrency={true}
                  type={'number'}
                  digitFormat={'currency'}
                  inputlabel='Take home Pay'
                  name='take_home_pay'
                  required={false}
                  value={employerDetails.takeHomePay ? employerDetails.takeHomePay : '0.00'}
                  maxlength={9}
                  disabled={false}
                  OnChange={(e: any) => handleInput(e)}
                  dollarTextClassName={dofClassName.dollarClass}
                  inputLabelClassname={dofClassName.marginBottomforInput1}
                  className={`${dofClassName.inputBoxHeight} ${dofClassName.textAlignRightForAmount}`}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12}>
              <Typography className={`${dofClassName.existCustHead} ${dofClassName.marginBottom} ${dofClassName.marginLeftRight}`}>
                Address Information
              </Typography>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className={`${dofClassName.marginLeftRight}`}>
              <RACTextbox
                inputlabel='Address Line 1'
                name='address_line_1'
                required={false}
                value={employerDetails.empAddress1}
                maxlength={30}
                type='text'
                OnChange={(e: any) => handleInput(e)}
                inputLabelClassname={dofClassName.marginBottomforInput1}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className={`${dofClassName.marginLeftRight} ${dofClassName.marginTop16px}`}>
              <RACTextbox
                inputlabel='Address Line 2'
                name='address_line_2'
                required={false}
                value={employerDetails.empAddress2}
                maxlength={30}
                type='text'
                OnChange={(e: any) => handleInput(e)}
                inputLabelClassname={dofClassName.marginBottomforInput1}
                className={`${dofClassName.inputBoxHeight}`}
              />
            </Grid>

            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginTop16px}`}>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <RACTextbox
                  inputlabel='Zip Code'
                  name='ZIPCode'
                  required={false}
                  value={employerDetails.empZipCode}
                  maxlength={10}
                  disabled={false}
                  type='text'
                  OnChange={(e: any) => handleInput(e)}
                  inputLabelClassname={dofClassName.marginBottomforInput1}
                  className={`${dofClassName.inputBoxHeight}`}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <RACTextbox
                  inputlabel='City'
                  name='city'
                  required={false}
                  value={employerDetails.empCity}
                  maxlength={30}
                  type='text'
                  OnChange={(e: any) => handleInput(e)}
                  inputLabelClassname={dofClassName.marginBottomforInput1}
                  className={`${dofClassName.inputBoxHeight}`}
                />
              </Grid>

            </Grid>


            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} className={`${dofClassName.displayFlex} ${dofClassName.spaceBetween} ${dofClassName.marginTop16px}`}>

              <Grid item lg={6} md={6} sm={6} xs={6}>
                <div className={dofClassName.inputBoxHeightForSelect}>
                  <RACSelect
                    inputLabel="State"
                    name="state"
                    required={false}
                    defaultValue={employerDetails.empState}
                    options={stateOptions}
                    onChange={(e: any) => { handleInput(e, 'state') }}
                    inputLabelClassName={dofClassName.marginBottomforInput1}
                  />
                </div>
              </Grid>

            </Grid>

            <Grid container spacing={2} lg={12} md={12} sm={12} xs={12} className={`${dofClassName.topMargin} ${dofClassName.spaceBetween}`}>
              <Grid item lg={6} md={6} sm={6} xs={6} className={dofClassName.textAlignLeft}>
                <RACButton
                  color='secondary'
                  variant='outlined'
                  onClick={() => { handlePreviousBtn() }}
                >
                  Previous
                </RACButton>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6} className={dofClassName.textAlignRight}>
                <RACButton
                  onClick={() => { handleContinueBtn() }}
                  color='primary'
                  variant='contained'
                  disabled={!
                    ((isVerified.zipCodeEmployer &&
                      isVerified.companyName &&
                      isVerified.employerPhone && isVerified.PayDayFrequency &&
                      isVerified.dayPaid) || employerDetails.companyName && employerDetails.paydayFrequency != '' && employerDetails.dayPaid != '' && employerDetails.employerPhone)}
                >
                  Continue
                </RACButton>
              </Grid>

            </Grid>

          </Grid>
          {/* </Card> */}
        </Grid>
      </Grid >
    </Grid>
  )
}