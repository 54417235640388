/* eslint-disable */
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import PayfonePrefill from '../components/PayfonePrefill';
import BasicInfo from '../components/BasicInfo';
import EmployerInfo from '../components/EmployerInfo';
import ResidenceInfo from '../components/ResidenceInfo';
import ReferenceInfo from '../components/ReferenceInfo';
import ReviewInfo from '../components/ReviewInfo';
import { ApprovalPage } from '../components/ApprovalPage';
import IdentityVerify from '../components/IdentifyVerify';
import UserInfo from '../components/UserInfo';
import DofStartingPage from '../components/DofStartingPage';

export const routesTestId = 'routesTestId';

export const Routes = () => (

      <div data-testid={routesTestId}>
        <Switch>
          <Route
            exact
            path={AppRoute.startingPage}
            key="/"
            component={DofStartingPage}
          />
          <Route
            exact
            path={AppRoute.payfoneprefill}
            key="/payfoneprefill"
            component={PayfonePrefill}
          />
          <Route
            exact
            path={AppRoute.basicInfo}
            key="/basicinfo"
            component={BasicInfo}
          />
          <Route
            exact
            path={AppRoute.employerInfo}
            key="/employerinfo"
            component={EmployerInfo}
          />
          <Route
            exact
            path={AppRoute.residenceInfo}
            key="/residenceinfo"
            component={ResidenceInfo}
          />
          <Route
            exact
            path={AppRoute.referenceInfo}
            key="/referenceinfo"
            component={ReferenceInfo}
          />
          <Route
            exact
            path={AppRoute.reviewInfo}
            key="/reviewInfo"
            component={ReviewInfo}
          />
          <Route
            exact
            path={AppRoute.ApprovalPage}
            key="/ApprovalPage"
            component={ApprovalPage}
          />
          <Route
            exact
            path={AppRoute.identifyVerify}
            key="/IdentityVerify"
            component={IdentityVerify}
          />
          <Route
            exact
            path={AppRoute.userInfo}
            key="/userInfo"
            component={UserInfo}
          />
        </Switch>
      </div>

);
