/*eslint-disable */
import { RACThemeProvider, makeStyles } from '@rentacenter/racstrap';
import clsx from 'clsx';

const History = require('history');
import { DofContextProvider } from '../context/DofContext';
// import { Router } from 'react-router';
import { Routes } from './Routes';
import { BrowserRouter } from 'react-router-dom';
interface Props {
  history: typeof History;
}

export const appTestId = 'appTestId';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    textAlign: 'center'
  },
}));

export const App = ({ history }: Props) => {
  const { isRenderedByContainer } = window;
  const classes = useStyles();

  return (
    <div data-testid={appTestId}>
      <RACThemeProvider>
        <DofContextProvider>
          <div className={clsx(classes.container)}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>

          </div>
        </DofContextProvider>
      </RACThemeProvider>
    </div>
  );
};




