/*eslint-disable */
import { RACModalCard, Typography, Grid, CircularProgress } from '@rentacenter/racstrap';

import loader from '../images/loader-icon.svg';

export function LoaderPopup(props: any) {
  return (
    <RACModalCard isOpen={true} maxWidth="xs" borderRadius={'15px'}>
      <Grid style={{
        justifyContent: 'center',
        margin: '10px 25px 25px 0px'
      }}>
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />

        </div>

        <Typography
          style={{
            fontSize: '17px',
            marginLeft: '18px',
            textAlign: 'center',
            marginTop: '8px'
          
          }}
        >
          {props?.message}
        </Typography>
      </Grid>
    </RACModalCard>
  );
}
