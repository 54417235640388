/* eslint-disable prettier/prettier */
import React, { Grid, Typography } from '@rentacenter/racstrap';

import doflogo from '../images/logo.svg';
import secureIcon from '../images/secure-icon.svg';
import { DofCssClasses } from '../styles/dofStyles';

export default function DofHeaders() {
  const dofClassName: any = DofCssClasses();
  return (
    <Grid className={dofClassName.parentClass}>
      <Grid>
        <img
          src={doflogo}
          style={{
            float: 'left',
            verticalAlign: 'middle',
            marginTop: '20px',
            marginLeft: '10px',
          }}
        />
        <Typography
          variant="h4"
          style={{
            float: 'left',
            marginLeft: '17px',
            marginTop: '20px',
            color: 'white',
            fontWeight: 'bolder',
          }}
        >
          Rental Order Form
        </Typography>
      </Grid>
      <Grid style={{ float: 'right' }}>
        <Grid>
          <img
            src={secureIcon}
            style={{ marginRight: '110px', marginTop: '30px', color: 'white' }}
          />
        </Grid>
        <Grid>
          <Typography
            variant="body2"
            style={{
              marginTop: '-20px',
              marginLeft: '17px',
              marginRight: '5px',
              color: 'white',
            }}
          >
            Secure Form
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
